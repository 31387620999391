const info2 = [{src:"arbolesDeVida",
                type:"gallery",
                card:"arbolesDeVidaCard",
                year:2016,
                info:{title:"arbolesDeVidaTitle",
                      headers:[
                              "arbolesDeVidaHeader0",
                              "arbolesDeVidaHeader1",
                              ],
                      paragraphs:[
                              "arbolesDeVidaParagraph0",
                              "arbolesDeVidaParagraph1",
                              ],
                      images:[
                              {type: "video",
                                src: "288441225"},
                              {type: "image",
                                src: "arbolesDeVida1"},
                              {type: "image",
                                src: "arbolesDeVida2"},
                              {type: "image",
                                src: "arbolesDeVida3"},
                              {type: "image",
                                src: "arbolesDeVida4"},
                              {type: "image",
                                src: "arbolesDeVida5"},
                              ]}},
               {src:"arvant",
                type:"gallery",
                card:"arvantCard",
                year:2018,
                info:{title:"arvantTitle",
                      headers:[
                              "arvantHeader0",
                              "arvantHeader1",
                              ],
                      paragraphs:[
                              "arvantParagraph1",
                              "arvantParagraph2",
                              "arvantParagraph3",
                              "arvantParagraph4",
                              ],
                      images:[
                              {type: "image",
                                src: "arvant1"},
                              {type: "image",
                                src: "arvant2"},
                              {type: "image",
                                src: "arvant3"},
                              {type: "image",
                                src: "arvant4"},
                              {type: "image",
                                src: "arvant5"},
                              {type: "image",
                                src: "arvant6"},
                              {type: "image",
                                src: "arvant7"},
                              ]}},
               {src:"aube",
                type:"gallery",
                card:"aubeCard",
                year:2017,
                info:{title:"aubeTitle",
                      headers:[
                              "aubeHeader0",
                              "aubeHeader1",
                              ],
                      paragraphs:[
                              "aubeParagraph0",
                              "aubeParagraph1",
                              "aubeParagraph2",
                              "aubeParagraph3",
                              "aubeParagraph4",
                              ],
                      images:[
                              {type: "image",
                                src: "aube1"},
                              {type: "image",
                                src: "aube2"},
                              {type: "image",
                                src: "aube3"},
                              {type: "image",
                                src: "aube4"},
                              {type: "image",
                                src: "aube5"},
                              {type: "image",
                                src: "aube6"},
                              {type: "image",
                                src: "aube7"},
                              {type: "image",
                                src: "aube8"},
                              ]}},
               {src:"belaBartok",
                type:"gallery",
                card:"belaBartokCard",
                year:2016,
                info:{title:"belaBartokTitle",
                      headers:[
                              "belaBartokHeader0",
                              "belaBartokHeader1",
                              "belaBartokHeader2",
                              ],
                      paragraphs:[
                              "belaBartokParagraph0",
                              "belaBartokParagraph1",
                              "belaBartokParagraph2",
                              "belaBartokParagraph3",
                              ],
                      images:[
                              {type: "image",
                                src: "belaBartok1"},
                              {type: "image",
                                src: "belaBartok2"},
                              {type: "image",
                                src: "belaBartok3"},
                              {type: "image",
                                src: "belaBartok4"},
                              {type: "image",
                                src: "belaBartok5"},
                              {type: "image",
                                src: "belaBartok6"},
                              {type: "image",
                                src: "belaBartok7"},
                              {type: "image",
                                src: "belaBartok8"},
                              {type: "image",
                                src: "belaBartok9"},
                              ]}},
               {src:"casaFemaria",
                type:"gallery",
                card:"casaFemariaCard",
                year:2018,
                info:{title:"casaFemariaTitle",
                      headers:[
                              "casaFemariaHeader0",
                              "casaFemariaHeader1",
                              ],
                      paragraphs:[
                              "casaFemariaParagraph0",
                              "casaFemariaParagraph1",
                              "casaFemariaParagraph2",
                              "casaFemariaParagraph3",
                              "casaFemariaParagraph4",
                              "casaFemariaParagraph5",
                              ],
                      images:[
                              {type: "image",
                                src: "casaFemaria1"},
                              {type: "image",
                                src: "casaFemaria2"},
                              {type: "image",
                                src: "casaFemaria3"},
                              {type: "image",
                                src: "casaFemaria4"},
                              {type: "image",
                                src: "casaFemaria5"},
                              {type: "image",
                                src: "casaFemaria6"},
                              {type: "image",
                                src: "casaFemaria7"},
                              {type: "image",
                                src: "casaFemaria8"},
                              {type: "image",
                                src: "casaFemaria9"},
                              {type: "image",
                                src: "casaFemaria10"},
                              {type: "image",
                                src: "casaFemaria11"},
                              {type: "image",
                                src: "casaFemaria12"},
                              {type: "image",
                                src: "casaFemaria13"},
                              {type: "image",
                                src: "casaFemaria14"},
                              {type: "image",
                                src: "casaFemaria15"},
                              {type: "image",
                                src: "casaFemaria16"},
                              {type: "image",
                                src: "casaFemaria17"},
                              {type: "image",
                                src: "casaFemaria18"},
                              {type: "image",
                                src: "casaFemaria19"},
                              {type: "image",
                                src: "casaFemaria20"},
                              {type: "image",
                                src: "casaFemaria21"},
                              {type: "image",
                                src: "casaFemaria22"},
                              {type: "image",
                                src: "casaFemaria23"},
                              {type: "image",
                                src: "casaFemaria24"},
                              {type: "image",
                                src: "casaFemaria25"},
                              {type: "image",
                                src: "casaFemaria26"},
                              {type: "image",
                                src: "casaFemaria27"},
                              {type: "image",
                                src: "casaFemaria28"},
                              {type: "image",
                                src: "casaFemaria29"},
                              {type: "image",
                                src: "casaFemaria30"},
                              {type: "image",
                                src: "casaFemaria31"},
                              {type: "image",
                                src: "casaFemaria32"},
                              ]}},
              // {src:"chaux",
              //  type:"gallery",
              //  card:"chauxCard",
              //  year:2017,
              //  info:{title:"chauxTitle",
              //        headers:[
              //                "chauxHeader0",
              //                "chauxHeader1",
              //                ],
              //        paragraphs:[
              //                "chauxParagraph0",
              //                "chauxParagraph1",
              //                "chauxParagraph2",
              //                ],
              //        images:[
              //                {type: "image",
              //                  src: "chaux1"},
              //                {type: "image",
              //                  src: "chaux2"},
              //                {type: "image",
              //                  src: "chaux3"},
              //                {type: "image",
              //                  src: "chaux4"},
              //                {type: "image",
              //                  src: "chaux5"},
              //                ]}},
               {src:"cinco",
                type:"gallery",
                card:"cincoCard",
                year:2017,
                info:{title:"cincoTitle",
                      headers:[
                              "cincoHeader0",
                              "cincoHeader1",
                              ],
                      paragraphs:[
                              "cincoParagraph0",
                              "cincoParagraph1",
                              "cincoParagraph2",
                              "cincoParagraph3",
                              "cincoParagraph4",
                              ],
                      images:[
                              {type: "image",
                                src: "cinco1"},
                              {type: "image",
                                src: "cinco2"},
                              {type: "image",
                                src: "cinco3"},
                              {type: "image",
                                src: "cinco4"},
                              {type: "image",
                                src: "cinco5"},
                              {type: "image",
                                src: "cinco6"},
                              {type: "image",
                                src: "cinco7"},
                              {type: "image",
                                src: "cinco8"},
                              {type: "image",
                                src: "cinco9"},
                              {type: "image",
                                src: "cinco10"},
                              {type: "image",
                                src: "cinco11"},
                              {type: "image",
                                src: "cinco12"},
                              {type: "image",
                                src: "cinco13"},
                              {type: "image",
                                src: "cinco14"},
                              {type: "image",
                                src: "cinco15"},
                              {type: "image",
                                src: "cinco16"},
                              {type: "image",
                                src: "cinco17"},
                              {type: "image",
                                src: "cinco18"},
                              {type: "image",
                                src: "cinco19"},
                              {type: "image",
                                src: "cinco20"},
                              {type: "image",
                                src: "cinco21"},
                              {type: "image",
                                src: "cinco22"},
                              {type: "image",
                                src: "cinco23"},
                              {type: "image",
                                src: "cinco24"},
                              {type: "image",
                                src: "cinco25"},
                              {type: "image",
                                src: "cinco26"},
                              {type: "image",
                                src: "cinco27"},
                              {type: "image",
                                src: "cinco28"},
                              {type: "image",
                                src: "cinco29"},
                              {type: "image",
                                src: "cinco30"},
                              {type: "image",
                                src: "cinco31"},
                              {type: "image",
                                src: "cinco32"},
                              {type: "image",
                                src: "cinco33"},
                              {type: "image",
                                src: "cinco34"},
                              {type: "image",
                                src: "cinco35"},
                              ]}},
               {src:"colodionNum13",
                type:"gallery",
                card:"colodionNum13Card",
                year:2013,
                info:{title:"colodionNum13Title",
                      headers:[
                              "colodionNum13Header0",
                              "colodionNum13Header1",
                              ],
                      paragraphs:[
                              "colodionNum13Paragraph0",
                              "colodionNum13Paragraph1",
                              "colodionNum13Paragraph2",
                              "colodionNum13Paragraph3",
                              ],
                      images:[
                              {type: "image",
                                src: "colodionNum131"},
                              {type: "image",
                                src: "colodionNum132"},
                              {type: "image",
                                src: "colodionNum133"},
                              {type: "image",
                                src: "colodionNum134"},
                              {type: "image",
                                src: "colodionNum135"},
                              {type: "image",
                                src: "colodionNum136"},
                              {type: "image",
                                src: "colodionNum137"},
                              {type: "image",
                                src: "colodionNum138"},
                              {type: "image",
                                src: "colodionNum139"},
                              {type: "image",
                                src: "colodionNum1310"},
                              {type: "image",
                                src: "colodionNum1311"},
                              {type: "image",
                                src: "colodionNum1312"},
                              ]}},
               {src:"elAlofonoDeLaVida",
                type:"gallery",
                card:"elAlofonoDeLaVidaCard",
                year:2010,
                info:{title:"elAlofonoDeLaVidaTitle",
                      headers:[
                              "elAlofonoDeLaVidaHeader0",
                              "elAlofonoDeLaVidaHeader1",
                              ],
                      paragraphs:[
                              "elAlofonoDeLaVidaParagraph0",
                              "elAlofonoDeLaVidaParagraph1",
                              "elAlofonoDeLaVidaParagraph2",
                              "elAlofonoDeLaVidaParagraph3",
                              ],
                      images:[
                              {type: "image",
                                src: "elAlofonoDeLaVida1"},
                              {type: "image",
                                src: "elAlofonoDeLaVida2"},
                              {type: "image",
                                src: "elAlofonoDeLaVida3"},
                              {type: "image",
                                src: "elAlofonoDeLaVida4"},
                              {type: "image",
                                src: "elAlofonoDeLaVida5"},
                              {type: "image",
                                src: "elAlofonoDeLaVida6"},
                              {type: "image",
                                src: "elAlofonoDeLaVida7"},
                              {type: "image",
                                src: "elAlofonoDeLaVida8"},
                              {type: "image",
                                src: "elAlofonoDeLaVida9"},
                              ]}},
               {src:"elCicloDeLaPiel",
                type:"gallery",
                card:"elCicloDeLaPielCard",
                year:2010,
                info:{title:"elCicloDeLaPielTitle",
                      headers:[
                              "elCicloDeLaPielHeader0",
                              "elCicloDeLaPielHeader1",
                              ],
                      paragraphs:[
                              "elCicloDeLaPielParagraph0",
                              "elCicloDeLaPielParagraph1",
                              "elCicloDeLaPielParagraph2",
                              ],
                      images:[
                              {type: "video",
                               src: "288891264"},
                              ]}},
               {src:"herakles",
                type:"gallery",
                card:"heraklesCard",
                year:2016,
                info:{title:"heraklesTitle",
                      headers:[
                              "heraklesHeader0",
                              "heraklesHeader1",
                              ],
                      paragraphs:[
                              "heraklesParagraph0",
                              "heraklesParagraph1",
                              "heraklesParagraph2",
                              "heraklesParagraph3",
                              ],
                      images:[
                              {type: "image",
                                src: "herakles1"},
                              {type: "image",
                                src: "herakles2"},
                              {type: "image",
                                src: "herakles3"},
                              {type: "image",
                                src: "herakles4"},
                              {type: "image",
                                src: "herakles5"},
                              {type: "image",
                                src: "herakles6"},
                              {type: "image",
                                src: "herakles7"},
                              ]}},
               {src:"instruccionesParaUnArquitecto",
                type:"gallery",
                card:"instruccionesParaUnArquitectoCard",
                year:2013,
                info:{title:"instruccionesParaUnArquitectoTitle",
                      headers:[
                              "instruccionesParaUnArquitectoHeader0",
                              "instruccionesParaUnArquitectoHeader1",
                              ],
                      paragraphs:[
                              "instruccionesParaUnArquitectoParagraph0",
                              "instruccionesParaUnArquitectoParagraph1",
                              "instruccionesParaUnArquitectoParagraph2",
                              "instruccionesParaUnArquitectoParagraph3",
                              ],
                      images:[
                              {type: "image",
                                src: "instruccionesParaUnArquitecto1"},
                              {type: "image",
                                src: "instruccionesParaUnArquitecto2"},
                              {type: "image",
                                src: "instruccionesParaUnArquitecto3"},
                              {type: "image",
                                src: "instruccionesParaUnArquitecto4"},
                              {type: "image",
                                src: "instruccionesParaUnArquitecto5"},
                              {type: "image",
                                src: "instruccionesParaUnArquitecto6"},
                              {type: "image",
                                src: "instruccionesParaUnArquitecto7"},
                              {type: "image",
                                src: "instruccionesParaUnArquitecto8"},
                              {type: "image",
                                src: "instruccionesParaUnArquitecto9"},
                              {type: "image",
                                src: "instruccionesParaUnArquitecto10"},
                              {type: "image",
                                src: "instruccionesParaUnArquitecto11"},
                              {type: "image",
                                src: "instruccionesParaUnArquitecto12"},
                              ]}},
               {src:"invencibleInvisible",
                type:"gallery",
                card:"invencibleInvisibleCard",
                year:2014,
                info:{title:"invencibleInvisibleTitle",
                      headers:[
                              "invencibleInvisibleHeader0",
                              "invencibleInvisibleHeader1",
                              ],
                      paragraphs:[
                              "invencibleInvisibleParagraph0",
                              "invencibleInvisibleParagraph1",
                              "invencibleInvisibleParagraph2",
                              "invencibleInvisibleParagraph3",
                              ],
                      images:[
                              {type: "image",
                                src: "invencibleInvisible1"},
                              {type: "image",
                                src: "invencibleInvisible2"},
                              {type: "image",
                                src: "invencibleInvisible3"},
                              {type: "image",
                                src: "invencibleInvisible4"},
                              ]}},
               {src:"laredo",
                type:"gallery",
                card:"laredoCard",
                year:2015,
                info:{title:"laredoTitle",
                      headers:[
                              "laredoHeader0",
                              "laredoHeader1",
                              ],
                      paragraphs:[
                              "laredoParagraph0",
                              "laredoParagraph1",
                              "laredoParagraph2",
                              "laredoParagraph3",
                              "laredoParagraph4",
                              "laredoParagraph5",
                              ],
                      images:[
                              {type: "image",
                                src: "laredo1"},
                              {type: "image",
                                src: "laredo2"},
                              {type: "image",
                                src: "laredo3"},
                              {type: "image",
                                src: "laredo4"},
                              {type: "image",
                                src: "laredo5"},
                              {type: "image",
                                src: "laredo6"},
                              {type: "image",
                                src: "laredo7"},
                              {type: "image",
                                src: "laredo8"},
                              {type: "image",
                                src: "laredo9"},
                              {type: "image",
                                src: "laredo10"},
                              {type: "image",
                                src: "laredo11"},
                              {type: "image",
                                src: "laredo12"},
                              {type: "image",
                                src: "laredo13"},
                              {type: "image",
                                src: "laredo14"},
                              {type: "image",
                                src: "laredo15"},
                              {type: "image",
                                src: "laredo16"},
                              ]}},
               {src:"liLiane",
                type:"gallery",
                card:"liLianeCard",
                year:2013,
                info:{title:"liLianeTitle",
                      headers:[
                              "liLianeHeader0",
                              "liLianeHeader1",
                              ],
                      paragraphs:[
                              "liLianeParagraph0",
                              "liLianeParagraph1",
                              "liLianeParagraph2",
                              "liLianeParagraph3",
                              ],
                      images:[
                              {type: "image",
                                src: "liLiane1"},
                              {type: "image",
                                src: "liLiane2"},
                              {type: "image",
                                src: "liLiane3"},
                              {type: "image",
                                src: "liLiane4"},
                              ]}},
               {src:"mantonegro",
                type:"gallery",
                card:"mantonegroCard",
                year:2018,
                info:{title:"mantonegroTitle",
                      headers:[
                              "mantonegroHeader0",
                              "mantonegroHeader1",
                              ],
                      paragraphs:[
                              "mantonegroParagraph0",
                              "mantonegroParagraph1",
                              "mantonegroParagraph2",
                              "mantonegroParagraph3",
                              "mantonegroParagraph4",
                              "mantonegroParagraph5",
                              "mantonegroParagraph6",
                              ],
                      images:[
                              {type: "image",
                                src: "mantonegro1"},
                              {type: "image",
                                src: "mantonegro2"},
                              {type: "image",
                                src: "mantonegro3"},
                              {type: "image",
                                src: "mantonegro4"},
                              {type: "image",
                                src: "mantonegro5"},
                              {type: "image",
                                src: "mantonegro6"},
                              {type: "image",
                                src: "mantonegro7"},
                              {type: "image",
                                src: "mantonegro8"},
                              {type: "image",
                                src: "mantonegro9"},
                              {type: "image",
                                src: "mantonegro10"},
                              {type: "image",
                                src: "mantonegro11"},
                              {type: "image",
                                src: "mantonegro12"},
                              {type: "image",
                                src: "mantonegro13"},
                              {type: "image",
                                src: "mantonegro14"},
                              {type: "image",
                                src: "mantonegro15"},
                              {type: "image",
                                src: "mantonegro16"},
                              ]}},
               {src:"mata",
                type:"gallery",
                card:"mataCard",
                year:2018,
                info:{title:"mataTitle",
                      headers:[
                              "mataHeader0",
                              "mataHeader1",
                              ],
                      paragraphs:[
                              "mataParagraph0",
                              "mataParagraph1",
                              "mataParagraph2",
                              "mataParagraph3",
                              "mataParagraph4",
                              ],
                      images:[
                              {type: "image",
                                src: "mata1"},
                              {type: "image",
                                src: "mata2"},
                              {type: "image",
                                src: "mata3"},
                              {type: "image",
                                src: "mata4"},
                              {type: "image",
                                src: "mata5"},
                              {type: "image",
                                src: "mata6"},
                              {type: "image",
                                src: "mata7"},
                              {type: "image",
                                src: "mata8"},
                              {type: "image",
                                src: "mata9"},
                              {type: "image",
                                src: "mata10"},
                              {type: "image",
                                src: "mata11"},
                              {type: "image",
                                src: "mata12"},
                              {type: "image",
                                src: "mata13"},
                              {type: "image",
                                src: "mata14"},
                              ]}},
               {src:"mazatlan",
                type:"gallery",
                card:"mazatlanCard",
                year:2016,
                info:{title:"mazatlanTitle",
                      headers:[
                              "mazatlanHeader0",
                              "mazatlanHeader1",
                              ],
                      paragraphs:[
                              "mazatlanParagraph0",
                              "mazatlanParagraph1",
                              "mazatlanParagraph2",
                              "mazatlanParagraph3",
                              "mazatlanParagraph4",
                              "mazatlanParagraph5",
                              "mazatlanParagraph6",
                              "mazatlanParagraph7",
                              "mazatlanParagraph8",
                              ],
                      images:[
                              {type: "image",
                                src: "mazatlan1"},
                              {type: "image",
                                src: "mazatlan2"},
                              {type: "image",
                                src: "mazatlan3"},
                              {type: "image",
                                src: "mazatlan4"},
                              {type: "image",
                                src: "mazatlan5"},
                              {type: "image",
                                src: "mazatlan6"},
                              {type: "image",
                                src: "mazatlan7"},
                              {type: "image",
                                src: "mazatlan8"},
                              {type: "image",
                                src: "mazatlan9"},
                              {type: "image",
                                src: "mazatlan10"},
                              {type: "image",
                                src: "mazatlan11"},
                              {type: "image",
                                src: "mazatlan12"},
                              {type: "image",
                                src: "mazatlan13"},
                              {type: "image",
                                src: "mazatlan14"},
                              {type: "image",
                                src: "mazatlan15"},
                              {type: "image",
                                src: "mazatlan16"},
                              {type: "image",
                                src: "mazatlan17"},
                              {type: "image",
                                src: "mazatlan18"},
                              {type: "image",
                                src: "mazatlan19"},
                              {type: "image",
                                src: "mazatlan20"},
                              {type: "image",
                                src: "mazatlan21"},
                              {type: "image",
                                src: "mazatlan22"},
                              {type: "image",
                                src: "mazatlan23"},
                              {type: "image",
                                src: "mazatlan24"},
                              {type: "image",
                                src: "mazatlan25"},
                              {type: "image",
                                src: "mazatlan26"},
                              {type: "image",
                                src: "mazatlan27"},
                              {type: "image",
                                src: "mazatlan28"},
                              {type: "image",
                                src: "mazatlan29"},
                              {type: "image",
                                src: "mazatlan30"},
                              {type: "image",
                                src: "mazatlan31"},
                              {type: "image",
                                src: "mazatlan32"},
                              ]}},
               {src:"onora",
                type:"gallery",
                card:"onoraCard",
                year:2013,
                info:{title:"onoraTitle",
                      headers:[
                              "onoraHeader0",
                              "onoraHeader1",
                              "onoraHeader2",
                              ],
                      paragraphs:[
                              "onoraParagraph0",
                              "onoraParagraph1",
                              "onoraParagraph2",
                              "onoraParagraph3",
                              "onoraParagraph4",
                              ],
                      images:[
                              {type: "image",
                                src: "onora1"},
                              {type: "image",
                                src: "onora2"},
                              {type: "image",
                                src: "onora3"},
                              {type: "image",
                                src: "onora4"},
                              {type: "image",
                                src: "onora5"},
                              {type: "image",
                                src: "onora6"},
                              {type: "image",
                                src: "onora7"},
                              {type: "image",
                                src: "onora8"},
                              ]}},
               {src:"pentHouse4",
                type:"gallery",
                card:"pentHouse4Card",
                year:2017,
                info:{title:"pentHouse4Title",
                      headers:[
                              "pentHouse4Header0",
                              "pentHouse4Header1",
                              ],
                      paragraphs:[
                              "pentHouse4Paragraph0",
                              "pentHouse4Paragraph1",
                              "pentHouse4Paragraph2",
                              "pentHouse4Paragraph3",
                              "pentHouse4Paragraph4",
                              "pentHouse4Paragraph5",
                              ],
                      images:[
                              {type: "image",
                                src: "pentHouse41"},
                              {type: "image",
                                src: "pentHouse42"},
                              {type: "image",
                                src: "pentHouse43"},
                              {type: "image",
                                src: "pentHouse44"},
                              {type: "image",
                                src: "pentHouse45"},
                              {type: "image",
                                src: "pentHouse46"},
                              {type: "image",
                                src: "pentHouse47"},
                              {type: "image",
                                src: "pentHouse48"},
                              {type: "image",
                                src: "pentHouse49"},
                              {type: "image",
                                src: "pentHouse410"},
                              {type: "image",
                                src: "pentHouse411"},
                              {type: "image",
                                src: "pentHouse412"},
                              {type: "image",
                                src: "pentHouse413"},
                              {type: "image",
                                src: "pentHouse414"},
                              ]}},
               {src:"planDeEscape",
                type:"gallery",
                card:"planDeEscapeCard",
                year:2009,
                info:{title:"planDeEscapeTitle",
                      headers:[
                              "planDeEscapeHeader0",
                              "planDeEscapeHeader1",
                              ],
                      paragraphs:[
                              "planDeEscapeParagraph0",
                              "planDeEscapeParagraph1",
                              ],
                      images:[
                              {type: "video",
                                src: "288629358"},
                              {type: "image",
                                src: "planDeEscape1"},
                              {type: "image",
                                src: "planDeEscape2"},
                              {type: "image",
                                src: "planDeEscape3"},
                              {type: "image",
                                src: "planDeEscape4"},
                              {type: "image",
                                src: "planDeEscape5"},
                              {type: "image",
                                src: "planDeEscape6"},
                              {type: "image",
                                src: "planDeEscape7"},
                              {type: "image",
                                src: "planDeEscape8"},
                              ]}},
               {src:"sillaSavia",
                type:"gallery",
                card:"sillaSaviaCard",
                year:2019,
                info:{title:"sillaSaviaTitle",
                      headers:[
                              "sillaSaviaHeader0",
                              "sillaSaviaHeader1",
                              ],
                      paragraphs:[
                              "sillaSaviaParagraph0",
                              "sillaSaviaParagraph1",
                              "sillaSaviaParagraph2",
                              "sillaSaviaParagraph3",
                              ],
                      images:[
                              {type: "image",
                                src: "sillaSavia1"},
                              {type: "image",
                                src: "sillaSavia2"},
                              {type: "image",
                                src: "sillaSavia3"},
                              {type: "image",
                                src: "sillaSavia4"},
                              {type: "image",
                                src: "sillaSavia5"},
                              {type: "image",
                                src: "sillaSavia6"},
                              {type: "image",
                                src: "sillaSavia7"},
                              {type: "image",
                                src: "sillaSavia8"},
                              {type: "image",
                                src: "sillaSavia9"},
                              ]}},
               {src:"canalDeNado",
                type:"gallery",
                card:"canalDeNadoCard",
                year:2019,
                info:{title:"canalDeNadoTitle",
                      headers:[
                              "canalDeNadoHeader0",
                              "canalDeNadoHeader1",
                              ],
                      paragraphs:[
                              "canalDeNadoParagraph0",
                              "canalDeNadoParagraph1",
                              ],
                      images:[
                              {type: "image",
                                src: "canalDeNado1"},
                              {type: "image",
                                src: "canalDeNado2"},
                              {type: "image",
                                src: "canalDeNado3"},
                              {type: "image",
                                src: "canalDeNado4"},
                              {type: "image",
                                src: "canalDeNado5"},
                              ]}},
               {src:"rompehueso",
                type:"gallery",
                card:"rompehuesoCard",
                year:2018,
                info:{title:"rompehuesoTitle",
                      headers:[
                              "rompehuesoHeader0",
                              "rompehuesoHeader1",
                              ],
                      paragraphs:[
                              "rompehuesoParagraph0",
                              "rompehuesoParagraph1",
                              "rompehuesoParagraph2",
                              "rompehuesoParagraph3",
                              "rompehuesoParagraph4",
                              "rompehuesoParagraph5",
                              "rompehuesoParagraph6",
                              ],
                      images:[
                              {type: "image",
                                src: "rompehueso1"},
                              {type: "image",
                                src: "rompehueso2"},
                              {type: "image",
                                src: "rompehueso3"},
                              {type: "image",
                                src: "rompehueso4"},
                              {type: "image",
                                src: "rompehueso5"},
                              {type: "image",
                                src: "rompehueso6"},
                              {type: "image",
                                src: "rompehueso7"},
                              {type: "image",
                                src: "rompehueso8"},
                              {type: "image",
                                src: "rompehueso9"},
                              {type: "image",
                                src: "rompehueso10"},
                              ]}},
               {src:"sema",
                type:"gallery",
                card:"semaCard",
                year:2013,
                info:{title:"semaTitle",
                      headers:[
                              "semaHeader0",
                              "semaHeader1",
                              ],
                      paragraphs:[
                              "semaParagraph0",
                              "semaParagraph1",
                              "semaParagraph2",
                              "semaParagraph3",
                              ],
                      images:[
                              {type: "video",
                                src: "288891365"},
                              {type: "image",
                                src: "sema1"},
                              {type: "image",
                                src: "sema2"},
                              {type: "image",
                                src: "sema3"},
                              {type: "image",
                                src: "sema4"},
                              {type: "image",
                                src: "sema5"},
                              {type: "image",
                                src: "sema6"},
                              {type: "image",
                                src: "sema7"},
                              {type: "image",
                                src: "sema8"},
                              {type: "image",
                                src: "sema9"},
                              {type: "image",
                                src: "sema10"},
                              {type: "video",
                                src: "288442260"},
                              ]}},
               {src:"senderos",
                type:"gallery",
                card:"senderosCard",
                year:2014,
                info:{title:"senderosTitle",
                      headers:[
                              "senderosHeader0",
                              "senderosHeader1",
                              ],
                      paragraphs:[
                              "senderosParagraph0",
                              "senderosParagraph1",
                              "senderosParagraph2",
                              ],
                      images:[
                              {type: "video",
                                src: "289150581"},
                              ]}},
               {src:"us",
                type:"gallery",
                card:"usCard",
                info:{
                      id:"Us",
                      headers:[
                              ],
                      paragraphs:[
                              "usFooter1",
                              "usFooter2",
                              "usFooter3",
                              "usFooter4",
                              "usFooter9",
                              "usFooter10",
                              ],
                      footer:[
                              "usParagraph2",
                              "usParagraph3",
                              "usParagraph4",
                              "usParagraph5",
                              "usParagraph6",
                              "usParagraph7",
                              "usParagraph8",
                              ],
                      images:[
                              {type: "image",
                                src: "studio2"}
                              ]}},
               {src:"contact",
                type:"gallery",
                card:"contactCard",
                info:{id:"Contact",
                      headers:[
                              ],
                      paragraphs:[
                              "contactParagraph1",
                              "contactParagraph2",
                              "contactParagraph3",
                              "contactParagraph4",
                              "contactParagraph5",
                              "contactParagraph6",
                              ],
                      images:[
                              {type: "image",
                                src: "contact1"}
                              ]}},
               {src:"studio",
                type:"gallery",
                card:"studioCard",
                info:{
                      headers:[
                              ],
                      paragraphs:[
                              "studioParagraph0",
                              "studioParagraph1",
                              "studioParagraph2",
                              "studioParagraph3",
                              ],
                      images:[
                              {type: "image",
                                src: "studio3"}
                              ]}},
];

export default { info2 };

